import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import Titlebar from "common/components/Titlebar";
import "common/assets/css/main-page.css";
import SuccessStories from "containers/Home/SuccessStories";
import GalleryPageSlider from "containers/Home/GalleryPageSlider";
import Seo from "components/seo";

const Gallery = () => {
  const seoData = {
    title: "Hair Wigs & Extensions Gallery | Radiance Hair Studio",
    description: "Explore the gallery at Radiance Hair Studio to see stunning transformations with premium wigs, hair extensions, and non-surgical hair solutions.",
    keywords: ["Hair Wigs & Extensions Gallery"],
  };

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />
        <Layout>
          <Titlebar title="Gallery" />
          <SuccessStories />
          <GalleryPageSlider />
          
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default Gallery;
