import React from "react";
import { useState } from "react";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import Container from "common/components/UI/Container";
import { SectionHeader } from "../global.style";
import Fade from "react-reveal/Fade";
import Heading from "common/components/Heading";
import SectionWrapper from "./galleryslider.style";
import YouTube from "react-youtube";
import LazyLoad from "react-lazyload";
// import { marginBottom, marginTop } from "styled-system";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function GalleryPageSlider() {
  const swiperBreakpoints = {
    // When viewport width is less than or equal to 767px (typical mobile size)
    767: {
      slidesPerView: 2.5, // Display 2 slides on mobile
      spaceBetween: 10, // Adjust as needed
    },
    // When viewport width is greater than 767px (larger screens)
    768: {
      slidesPerView: 2.5, // Display 1 slide on larger screens
      spaceBetween: 20, // Adjust as needed
    },
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const opts = {
    height: "400",
    width: "100%",
    playerVars: {

      autoplay: 0,
    },
  };
  const opts2 = {
    height: "170",
    width: "100%",
    playerVars: {

      autoplay: 0,
    },
  };

  return (
    <SectionWrapper id="successStories2">
      <Fade top>
        <SectionHeader>
          <Heading
            as="h5"
            content="Photo Gallery"
            style={{ marginBottom: "0" }}
          />
          <Heading content="Inside Look at Our Salon" />
        </SectionHeader>
      </Fade>

      <div className="myContainer">
      <div className="row">
        <div className="col-md-6">
          <LazyLoad height={200} offset={100}>
            <YouTube videoId="Zji0rczlF6w" opts={opts} />
          </LazyLoad>
          <div className="row mt-4">
            <div className="col-md-6">
              <LazyLoad height={200} offset={100}>
                <YouTube videoId="mqDjxw5z3aU" opts={opts2} />
              </LazyLoad>
            </div>
            <div className="col-md-6">
              <LazyLoad height={200} offset={100}>
                <YouTube videoId="6FuzF3WxNP0" opts={opts2} />
              </LazyLoad>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row mb-4">
            <div className="col-md-6">
              <LazyLoad height={200} offset={100}>
                <YouTube videoId="lOQyRd6RwPw" opts={opts2} />
              </LazyLoad>
            </div>
            <div className="col-md-6">
              <LazyLoad height={200} offset={100}>
                <YouTube videoId="UFLhkbkiCfw" opts={opts2} />
              </LazyLoad>
            </div>
          </div>
          <LazyLoad height={200} offset={100}>
            <YouTube videoId="EzeHzN5HzzA" opts={opts} />
          </LazyLoad>
        </div>
      </div>
    </div>
    </SectionWrapper>
  );
}

export default GalleryPageSlider;
